import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import networks from './networks';
import auth from './auth';
import backlogs from './backlogs';
import sites from './sites';
import toastr from './toastr';
const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  networks,
  auth,
  backlogs,
  sites,
  toastr,
});

export default createRootReducer;
