
import { SET_TOASTR } from 'src/actions/toastr';

export type toastrStateType = {
    isOpen: boolean;
    title?: string;
    message: string;
    isSuccess: boolean;
}


const initialState: toastrStateType = {
    isOpen: false,
    title: '',
    message: '',
    isSuccess: true
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TOASTR:
            console.log("setToastr", action.data)
            return {
                ...state,
                isOpen: true,
                isSuccess: true,
                ...action.data as Partial<toastrStateType>
            }
        default:
            return state;
    }
}
