import { ApolloClient, InMemoryCache, split, HttpLink, ApolloLink, concat, from } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from "@apollo/client/link/error";
import { endpoints } from '../config';

let baseUrl = `${endpoints.base_url_insights}graphql`;

if (process.env.REACT_APP_GRAPHQL_SOURCE === 'local') {
  baseUrl = 'http://localhost:4000/graphql';
}

export const resolveClient = (token, keycloak) => {
  const httpLink = new HttpLink({ uri: baseUrl });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    // const token = localStorage.getItem('access_token');

    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      }
    });

    return forward(operation);
  });

  const wsLink = new WebSocketLink({
    uri: baseUrl.replace('http', 'ws'),
    options: {
      timeout: 60000,
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    }
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    concat(authMiddleware, httpLink),
  );

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) {
      if (networkError == "ServerError: Response not successful: Received status code 401") keycloak.logout({ redirectUri: window.location.origin })
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const cache = new InMemoryCache();

  return new ApolloClient({ link: from([errorLink, link]), cache });
};
