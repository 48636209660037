import axios from 'axios';
import * as config from '../config';

export const SETISLOADINGINSITES = 'sites/SETISLOADINGINSITES';
export const SETSITES = 'sites/SETSITES';

export const getSites = (
  onSuccess: (data: any) => void = () => { },
  onError: (err: any) => void = () => { }
) => {

  return (dispatch) => {

    dispatch({ type: SETISLOADINGINSITES, isLoadingSites: true });

    axios(`${config.endpoints.base_url}v1/sites`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    }).then((resp) => {
      dispatch({ type: SETISLOADINGINSITES, isLoadingSites: false });
      dispatch({ type: SETSITES, sites: transformSites(resp.data.data.rows) });
      return onSuccess(resp.data);
    }).catch((err) => {
      onError(err);
    });
  };
};

const transformSites = (sites) => {
  sites = sites.filter((site) => {
    return !site.name || site.name !== 'Unallocated';
  });

  return sites.map(site => {
    const tags = site.tags.map((tag) => {
      return {
        ...tag,
        value: tag.id,
        label: tag.name,
      }
    });

    return {
      ...site,
      location: {
        coordinates: {
          lat: parseFloat(site.site_attributes.find(attribute => attribute.attribute === 'lat')?.value),
          lng: parseFloat(site.site_attributes.find(attribute => attribute.attribute === 'lng')?.value),
        },
        street: site.site_attributes.find(attribute => attribute.attribute === 'street')?.value,
        suburb: site.site_attributes.find(attribute => attribute.attribute === 'suburb')?.value,
        state: site.site_attributes.find(attribute => attribute.attribute === 'state')?.value,
        postCode: site.site_attributes.find(attribute => attribute.attribute === 'postCode')?.value,
        country: site.site_attributes.find(attribute => attribute.attribute === 'country')?.value
      },
      timezone: site.site_attributes.find(attribute => attribute.attribute === 'timezone')?.value,
      image: site.site_attributes.find(attribute => attribute.attribute === 'image')?.value,
      blueprint: site.site_attributes.find(attribute => attribute.attribute === 'blueprint')?.value,
      tags
    };
  });
};