import { SETISLOADINGINNETWORKS, SETNETWORKS, SETCURRENTNETWORK, LOGOUT, SETLICENSETYPES, SETLICENSETIERS } from '../actions/networks';
import { licenseTypeOptions, licenseTierOptions } from '../constants';

const initialState = {
  isLoadingNetworks: false,
  networks: [],
  isAddingSite: false,
  isEditingSite: false,
  currentNetwork: {},
  licenseTypes: licenseTypeOptions,
  licenseTiers: licenseTierOptions
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETISLOADINGINNETWORKS:
      return {
        ...state,
        isLoadingNetworks: action.isLoadingNetworks
      }
    case SETNETWORKS:
      return {
        ...state,
        networks: action.networks
      }
    case SETCURRENTNETWORK:
      return {
        ...state,
        currentNetwork: action.currentNetwork
      }
    case LOGOUT:
      return {
        ...state,
        networks: [],
        currentNetwork: {}
      }
    case SETLICENSETYPES:
      return {
        ...state,
        licenseTypes: action.licenseTypes
      }
    case SETLICENSETIERS:
      return {
        ...state,
        licenseTiers: action.licenseTiers
      }
    default:
      return state;
  }
}
